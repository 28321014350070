import { createRouter, createWebHistory } from 'vue-router'
import store from '../components/store';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/ehealth-result-report',
    name: 'EhealthResultReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/reports/EHealthResultReport.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.name !== 'Login')) {
    if (store.getters.isAuthenticated) {
      next();
      return
    }
    next('/login');
  } else {
    next();
  }
})

export default router
