import { createStore } from 'vuex'
import auth from "./modules/auth";

const debug = process.env.NODE_ENV !== "production";

export default new createStore({
    modules: {
        auth
    },
    strict: debug
});